





















import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import CooTranslationDialogue from "@/vue/components/dialogues/CooTranslationDialogue.vue";
import utils from "@/utilities/Utils";
import { CooTranslationVM, ICooTranslationVM } from "@/model/Api/VM/CooTranslationVM";
import { Country } from "@/model/Country";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        CooTranslationDialogue
    }
})

export default class CooTranslations extends Vue {

    async mounted(): Promise<void> { 
        this.loadCountries();
    }
    
    //
    // -- properties
    //

    private translationHeaders = [
        { text: "Language Code", value: "languageCode", sortable: true },
        { text: "Translation", value: "cooTranslation.translation", sortable: true }   
    ];

    private translationList: Array<CooTranslationVM> = [];
    private countries: Array<Country> = [];
    private countryID: string = utils.emptyGuidValue;

    //
    // -- Watchers
    //

    @Watch("countryID")
    private onCountryIDChanged() {
        if (this.countryID == undefined) {
            this.countryID = utils.emptyGuidValue;
        }
        this.refreshLoad();
    }

    //
    // -- methods
    //

    async loadCountries(): Promise<void> {
        this.countries = [];
        const response = await apiClient.get("/api/country/countries");
        this.countries.push(...response);
    }

    addNew(): void {
        const dialog: CooTranslationDialogue = this.$refs.cooTranslationDialogue as CooTranslationDialogue;
        dialog.add(this.countryID);
    }

    edit(ct: ICooTranslationVM): void {
        const dialog: CooTranslationDialogue = this.$refs.cooTranslationDialogue as CooTranslationDialogue;
        dialog.edit(ct.cooTranslation.countryID, ct.cooTranslation.languageID, ct.languageCode);
    }

    refreshLoad(): void {
        this.translationList = [];
        this.loadTranslations();
    }

    async loadTranslations(): Promise<void> {
        if (this.countryID == utils.emptyGuidValue) return;

        const response = await apiClient.get(`api/translation/loadAllCoo?countryID=${this.countryID}`);
        this.translationList.push(...response.map((t: ICooTranslationVM) => new CooTranslationVM(t)));
        console.log(this.translationList)
    }
}
