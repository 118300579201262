import { IWashcare, Washcare } from "../../Washcare";
import { IWashcareTranslationVM, WashcareTranslationVM } from "./WashcareTranslationVM";
import { WashcareTranslation } from "../../WashcareTranslation";
import { mapData } from "@/utilities/DataMapping";

export interface IWashcareVM {
    washcare: IWashcare;
    translations: Array<IWashcareTranslationVM>;
}

export class WashcareVM implements IWashcareVM {

    constructor(data?: IWashcareVM) {
        if (data) this.update(data);
    }

    update(data: IWashcareVM): void {
        mapData(data, { 
            root: () => this,
            washcare: () => new Washcare(),
            translations: () => new WashcareTranslationVM(),
            translation: () => new WashcareTranslation()

        });
    }

    washcare: Washcare = new Washcare();
    translations: Array<WashcareTranslationVM> = [];
}
