






























import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { CooTranslation, ICooTranslation } from "@/model/CooTranslation";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Language } from "@/model/Language";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class CooTranslationDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("cooTranslationForm") private readonly cooTranslationForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private isEdit: boolean = false;
    private translation: CooTranslation = new CooTranslation();
    private languages: Array<Language> = [];

    //
    // -- methods
    //

    async add(id: string): Promise<void> {
        this.reset();
        this.translation.countryID = id;
        this.loadLanguages(id);
        this.dialogueTitle = "New Translation Details"
        this.isEdit = false;
        this.showDialogue = true;
    }

    async edit(countryID: string, languageID: string, languageCode: string): Promise<void> {
        this.reset();
        const cooTranslationData: ICooTranslation = await apiClient.get(`api/translation/loadCooTranslation?countryID=${countryID}&languageID=${languageID}`);
        this.translation.update(cooTranslationData);
        this.dialogueTitle = "Translation Details - " + languageCode;
        this.isEdit = true;
        this.showDialogue = true;
    }

    async loadLanguages(id: string): Promise<void> {
        this.languages = [];
        const response = await apiClient.get(`/api/language/loadAvailableCooTranslationLanguages?id=${id}`);
        this.languages.push(...response);
    }

    private reset() {
        utils.resetObject(this.translation);
        this.cooTranslationForm?.resetValidation();
    }

    private async save() {
        if (!this.cooTranslationForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/translation/saveCooTranslation", this.translation, "coo-translation-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
        
    }
}
