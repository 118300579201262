import { render, staticRenderFns } from "./Languages.vue?vue&type=template&id=7eb24c78&scoped=true&"
import script from "./Languages.vue?vue&type=script&lang=ts&"
export * from "./Languages.vue?vue&type=script&lang=ts&"
import style0 from "./Languages.vue?vue&type=style&index=0&id=7eb24c78&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eb24c78",
  null
  
)

export default component.exports