













































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import LanguageSearchPage from "@/vue/components/languagePages/LanguageSearchPage.vue";
import Translations from "@/vue/components/languagePages/Translations.vue";
import CooTranslations from "@/vue/components/languagePages/CooTranslations.vue";
import { LanguageVM, ILanguageVM } from "@/model/Api/VM/LanguageVM";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { WashcareVM, IWashcareVM } from "@/model/Api/VM/WashcareVM";

@Component({
    components: { 
        ApiButton,
        LanguageSearchPage,
        CooTranslations,
        Translations
    }
})

export default class Languages extends Vue { 

    //
    // -- properties
    //

    // language page
    private infiniteId: number = + new Date();
    private languageSearchParameters = new SearchParameters();
    private languageList: Array<LanguageVM> = [];

    // wc translations page
    private translationSearchParameters = new SearchParameters();
    private wcList: Array<WashcareVM> = [];

    //
    // -- methods
    //

    refreshLanguageSearch(): void {
        this.languageList = [];
        this.languageSearchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async languageInfiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/language/search", this.languageSearchParameters);
        if (response.list.length) {
            this.languageList.push(...response.list.map((l: ILanguageVM) => new LanguageVM(l)));
            this.languageSearchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }

    async loadTranslationWashcares(): Promise<void> {
        this.wcList = [];

        if (this.translationSearchParameters.numericID == 0) return;

        const response = await apiClient.post("/api/translation/washcareTranslationsByType", this.translationSearchParameters);
        this.wcList.push(...response.map((t: IWashcareVM) => new WashcareVM(t)));
    }
}

