




















































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import { Washcare } from "@/model/Washcare";
import { WashcareTranslationVM, IWashcareTranslationVM } from "@/model/Api/VM/WashcareTranslationVM";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class WashcareDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("washcareForm") private readonly washcareForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private washcare: Washcare = new Washcare();
    private translations: Array<WashcareTranslationVM> = [];
    private currentLanguage: WashcareTranslationVM = new WashcareTranslationVM();

    //
    // -- computed
    //

    private get translationBoxDisabled() {
        var retVal = true;

        this.translations.forEach(trans => {
            if (trans.isSelected) {
                retVal = false;
            }
        });

        return retVal;
    }

    //
    // -- methods
    //

    async add(id: number): Promise<void> {
        this.reset();
        this.washcare.typeID = id;
        this.dialogueTitle = "New Washcare Translation"
        await this.loadAvailableLanguages();
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        const response = await apiClient.get(`api/translation/loadWashcare?id=${id}`);
        this.washcare.update(response.washcare);
        this.translations.push(...response.translations.map((t: IWashcareTranslationVM) => new WashcareTranslationVM(t)));
        this.dialogueTitle = "Edit Washcare Translation";
        this.showDialogue = true;
    }

    async loadAvailableLanguages(): Promise<void> {
        this.translations = [];
        const response = await apiClient.get(`/api/translation/loadAvailableWashcareLanguages`);
        this.translations.push(...response.map((t: IWashcareTranslationVM) => new WashcareTranslationVM(t)));
    }

    setCurrentLanguage(wt: IWashcareTranslationVM): void {
        this.translations.forEach(trans => {
            if (trans.language.id == this.currentLanguage.language.id) {
                trans.translation.translation = this.currentLanguage.translation.translation;
                trans.isSelected = false;
            }
        });

        this.translations.forEach(trans => {
            if (trans.language.id == wt.language.id) {
                trans.isSelected = true;
                this.currentLanguage.update(trans);
            }
        });
    }

    private reset() {
        utils.resetObject(this.washcare);
        utils.resetObject(this.currentLanguage);
        this.translations = [];
        this.washcareForm?.resetValidation();
    }

    private async save() {
        if (!this.washcareForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        this.translations.forEach(trans => {
            if (trans.language.id == this.currentLanguage.language.id) {
                trans.translation.translation = this.currentLanguage.translation.translation;
                trans.isSelected = false;
            }
        });

        const postData = {
            washcare: this.washcare,
            translations: this.translations
        }

        const response: ISaveResponse = await apiClient.post("/api/translation/saveWashcareTranslation", postData, "washcare-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("loadWashcares");
            this.$emit("refreshLanguageSearch");  
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
