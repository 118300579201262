





























































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import LanguageDialogue from "@/vue/components/dialogues/LanguageDialogue.vue";
import utils from "@/utilities/Utils";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { LanguageVM, ILanguageVM } from "@/model/Api/VM/LanguageVM";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        LanguageDialogue
    }
})

export default class LanguageSearchPage extends Vue {
    
    //
    // -- properties
    //

    private languageHeaders = [
        { text: "Language Code", value: "language.reference", sortable: true },
        { text: "Description", value: "language.description", sortable: true },
        { text: "AC Translations", value: "completeAdditionalCareTranslations", align: "center", sortable: true },
        { text: "Component Translations", value: "completeComponentTranslations", align: "center", sortable: true },
        { text: "Material Translations", value: "completeMaterialTranslations", align: "center", sortable: true },
        { text: "QR Washcare", value: "language.isQRWashcare", align: "center", sortable: true },
        { text: "Is Active", value: "language.isActive", align: "center", sortable: false }  
    ];

    @Prop({}) private infiniteId !: number;
    @Prop({}) private searchParameters !: SearchParameters;
    @Prop({}) private languageList !: Array<LanguageVM>;

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshLanguageSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    addNew(): void {
        const dialog: LanguageDialogue = this.$refs.languageDialogue as LanguageDialogue;
        dialog.add();
    }

    edit(language: ILanguageVM): void {
        const dialog: LanguageDialogue = this.$refs.languageDialogue as LanguageDialogue;
        dialog.edit(language.language.id);
    }

    refreshLanguageSearch(): void {
        this.$emit("refreshLanguageSearch");
    }

    loadWashcares(): void {
        this.$emit("loadTranslationWashcares");
    }

    infiniteLoadingHandler(stateChanger: StateChanger): void {
        this.$emit("infiniteLoadingHandler", stateChanger);        
    }
}
