import { IWashcareTranslation, WashcareTranslation } from "../../WashcareTranslation";
import { ILanguage, Language } from "../../Language";
import { mapData } from "@/utilities/DataMapping";

export interface IWashcareTranslationVM {
    translation: IWashcareTranslation;
    language: ILanguage;

    isSelected: boolean;
}

export class WashcareTranslationVM implements IWashcareTranslationVM {

    constructor(data?: IWashcareTranslationVM) {
        if (data) this.update(data);
    }

    update(data: IWashcareTranslationVM): void {
        mapData(data, { 
            root: () => this,
            translation: () => new WashcareTranslation(),
            language: () => new Language()
        });
    }

    translation: WashcareTranslation = new WashcareTranslation();
    language: Language = new Language();

    isSelected: boolean = false;
}
