var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('div',{staticClass:"text-h4"},[_vm._v("Languages")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3 mb-2",attrs:{"label":"Search","append-icon":"mdi-magnify","clearable":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchParameters.searchText),callback:function ($$v) {_vm.$set(_vm.searchParameters, "searchText", $$v)},expression:"searchParameters.searchText"}}),_c('api-button',{staticClass:"mb-2",on:{"click":_vm.addNew}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Add new")],1)],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.languageHeaders,"items":_vm.languageList,"disable-pagination":"","hide-default-footer":"","dense":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.completeAdditionalCareTranslations",fn:function(ref){
var item = ref.item;
return [(item.completeAdditionalCareTranslations)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(!item.completeAdditionalCareTranslations)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("There are missing Addition Care Translations for this Language")])]):_vm._e()]}},{key:"item.completeComponentTranslations",fn:function(ref){
var item = ref.item;
return [(item.completeComponentTranslations)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(!item.completeComponentTranslations)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("There are missing Component Translations for this Language")])]):_vm._e()]}},{key:"item.completeMaterialTranslations",fn:function(ref){
var item = ref.item;
return [(item.completeMaterialTranslations)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(!item.completeMaterialTranslations)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("There are missing Material Translations for this Language")])]):_vm._e()]}},{key:"item.language.isQRWashcare",fn:function(ref){
var item = ref.item;
return [(item.language.isQRWashcare)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(!item.language.isQRWashcare)?_c('v-icon',[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.language.isActive",fn:function(ref){
var item = ref.item;
return [(item.language.isActive)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(!item.language.isActive)?_c('v-icon',[_vm._v("mdi-close")]):_vm._e()]}}],null,true)}),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteLoadingHandler}}),_c('language-dialogue',{ref:"languageDialogue",on:{"loadWashcares":_vm.loadWashcares,"refreshLanguageSearch":_vm.refreshLanguageSearch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }