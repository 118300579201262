import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IWashcareTranslation {
    id: string;
    washcareID: string;
    languageID: string;
    translation: string;
}

export class WashcareTranslation implements IWashcareTranslation {

    constructor(data?: IWashcareTranslation) {
        if (data) this.update(data);
    }

    update(data: IWashcareTranslation): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    washcareID: string = utils.emptyGuidValue;
    languageID: string = utils.emptyGuidValue;
    translation: string = "";

    get isComplete(): boolean {
        return !utils.isEmptyOrWhitespace(this.translation);
    }
}
