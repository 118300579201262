import { ICooTranslation, CooTranslation } from "../../CooTranslation";
import { mapData } from "@/utilities/DataMapping";

export interface ICooTranslationVM {
    cooTranslation: ICooTranslation;
    languageCode: string;
}

export class CooTranslationVM implements ICooTranslationVM {

    constructor(data?: ICooTranslationVM) {
        if (data) this.update(data);
    }

    update(data: ICooTranslationVM): void {
        mapData(data, { 
            root: () => this,
            cooTranslation: () => new CooTranslation()
        });
    }

    cooTranslation: CooTranslation = new CooTranslation();
    languageCode: string = "";
}
