import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ICooTranslation {
    countryID: string;
    languageID: string;
    translation: string;
}

export class CooTranslation implements ICooTranslation {

    constructor(data?: ICooTranslation) {
        if (data) this.update(data);
    }

    update(data: ICooTranslation): void {
        mapData(data, { root: () => this });
    }

    countryID: string = utils.emptyGuidValue;
    languageID: string = utils.emptyGuidValue;
    translation: string = "";
}
