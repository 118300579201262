import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ILanguage {
    id: string;
    reference: string;
    description: string;
    isActive: boolean;
    isQRWashcare: boolean;
    sortOrder: number;
}

export class Language implements ILanguage {

    constructor(data?: ILanguage) {
        if (data) this.update(data);
    }

    update(data: ILanguage): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    reference: string = "";
    description: string = "";
    isActive: boolean = false;
    isQRWashcare: boolean = false;
    sortOrder: number = 0
}
