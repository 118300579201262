
































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Language, ILanguage } from "@/model/Language";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class LanguageDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("languageForm") private readonly languageForm!: VForm;  

    private showDialogue: boolean = false;
    private language: Language = new Language();
    private isEdit: boolean = false;

    //
    // -- methods
    //

    add(): void {
        this.reset();
        this.isEdit = false;
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.isEdit = true;
        const languageData: ILanguage = await apiClient.get(`api/language/Load?id=${id}`);
        this.language.update(languageData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.language);
        this.languageForm?.resetValidation();
    }

    private async save() {
        if (!this.languageForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/language/save", this.language, "language-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("loadWashcares");
            this.$emit("refreshLanguageSearch");  
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
