























































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import WashcareDialogue from "@/vue/components/dialogues/WashcareDialogue.vue";
import { Language } from "@/model/Language";
import { Prop } from "vue-property-decorator";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { WashcareType } from "@/model/WashcareType";
import { WashcareVM, IWashcareVM } from "@/model/Api/VM/WashcareVM";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        WashcareDialogue
    }
})

export default class Translations extends Vue {

    async mounted(): Promise<void> { 
        this.loadLanguageHeaders();
        this.loadWashcareTypes();
    }
    
    //
    // -- properties
    //

    @Prop({}) private searchParameters !: SearchParameters;
    @Prop({}) private wcList !: Array<WashcareVM>;
    private languageList: Array<Language> = [];
    private wcTypes: Array<WashcareType> = [];

    //
    // -- Watchers
    //

    @Watch("searchParameters.numericID")
    private onNumericIDChanged() {
        if (this.searchParameters.numericID == undefined) {
            this.searchParameters.numericID = 0;
        }

        this.loadWashcares();
    }

    //
    // -- methods
    //

    async loadLanguageHeaders(): Promise<void> {
        this.languageList = [];
        const response = await apiClient.get("/api/language/loadActiveLanguages");
        this.languageList.push(...response);
    }

    async loadWashcareTypes(): Promise<void> {
        this.wcTypes = [];
        const response = await apiClient.get("/api/translation/washcareTypes");
        this.wcTypes.push(...response);
    }

    addNew(): void {
        const dialog: WashcareDialogue = this.$refs.washcareDialogue as WashcareDialogue;
        dialog.add(this.searchParameters.numericID);
    }

    edit(ct: IWashcareVM): void {
        const dialog: WashcareDialogue = this.$refs.washcareDialogue as WashcareDialogue;
        dialog.edit(ct.washcare.id);
    }

    loadWashcares(): void {
        this.$emit("loadTranslationWashcares");
    }

    refreshLanguageSearch(): void {
        this.$emit("refreshLanguageSearch");
    }
}
