import { ILanguage, Language } from "../../Language";
import { mapData } from "@/utilities/DataMapping";

export interface ILanguageVM {
    language: ILanguage;
    completeAdditionalCareTranslations: boolean;
    completeComponentTranslations: boolean;
    completeMaterialTranslations: boolean;
}

export class LanguageVM implements ILanguageVM {

    constructor(data?: ILanguageVM) {
        if (data) this.update(data);
    }

    update(data: ILanguageVM): void {
        mapData(data, { 
            root: () => this,
            language: () => new Language()
        });
    }

    language: Language = new Language();
    completeAdditionalCareTranslations: boolean = false;
    completeComponentTranslations: boolean = false;
    completeMaterialTranslations: boolean= false;
}